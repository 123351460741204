import dayjs from 'dayjs';
import { findIndex, isEqual, pick } from 'lodash-es';
import { useStorage } from '@vueuse/core';
import { Service } from '@/types/service';
import { computed, useNuxtApp, defineStore } from '#imports';

import {
  FlightAirportDefaultSuggestion,
  FlightAirportSuggestion,
  FlightDeal,
  FlightSearchParams,
  FlightCalendarFaresResponse,
  Flight,
  FlightSearchHistory,
  FlightSearchResponse,
  FlightShareParam,
  FlightByCode,
  FlightLiveCrawlMonitorParams,
  FlightLiveCrawlParams,
  FlightLiveCrawlResponse,
  FlightCabinType,
} from '@/types/flight';

export const useFlightService = () => {
  const { $apifront } = useNuxtApp();

  const getAirportSuggestions = (params: { query: string; lang: string; country?: string }) =>
    $apifront<Service<FlightAirportSuggestion[]>>('/fl/autocomplete/search', { params });

  const getAirportDefaultSuggestions = (params: {
    lang: string;
    country: string;
    depAirport?: string;
    airline?: string;
  }) => $apifront<Service<FlightAirportDefaultSuggestion[]>>('/fl/autocomplete/suggestion', { params });

  const getFlightHomepagePromos = (params: { country: string; currency: string }) =>
    $apifront<Service<FlightDeal[]>>('/fl/promo/homepage-list', { params });

  const getFareCalendar = (
    params: Pick<FlightSearchParams, 'depAirport' | 'arrAirport' | 'currency'> & {
      isRoundTrip: boolean;
      tripType: FlightCabinType;
    },
  ) => $apifront<Service<FlightCalendarFaresResponse>>('/fl/flight/fare-calendar', { params });

  const getPopularRoutes = (params: { lang: string; country: string }) =>
    $apifront<
      Service<
        {
          depAirport: string;
          arrAirport: string;
          depCity: string;
          arrCity: string;
          depCityEN: string;
          arrCityEN: string;
        }[]
      >
    >('/fl/content/popular-routes', { params });

  const getPopularFlights = (params: { destination: string; page?: number; lang: string; country: string }) =>
    $apifront<
      Service<{
        pages: number;
        routes: {
          code: string;
          name: string;
          nameEN: string;
          city: string;
          cityEN: string;
        }[];
      }>
    >('/contents/flight/country-popular-destinations', { params });

  const flightLiveCrawl = (params: FlightLiveCrawlParams, options?: any) =>
    $apifront<Service<FlightLiveCrawlResponse>>('/flight/livecrawl', {
      method: 'post',
      body: params,
      v2: true,
      ...options,
    });

  const flightLiveCrawlRoundTrip = (
    params: FlightSearchParams & { depCode: string; depTraCode: string; lang: string },
    options?: any,
  ) =>
    $apifront<
      Service<{
        dep: {
          reqId: string;
          progress: number;
          countJob: number;
        };
        ret?: {
          reqId: string;
          progress: number;
          countJob: number;
        };
      }>
    >('/fl/flight/livecrawl-roundtrip', { method: 'post', body: params, ...options });

  const flightLiveCrawlMonitor = (params: FlightLiveCrawlMonitorParams, options?: any) =>
    $apifront<
      Service<{
        progress: number;
        count: number;
        expiresAt: number;
        remainingTime: number;
        lastUpdate: number;
        flights: Flight[];
        messages: {
          msgId: string;
          variable: Record<string, string>;
        }[];
      }>
    >('/flight/livecrawl-monitor', { method: 'post', retry: 3, retryDelay: 500, body: params, v2: true, ...options });

  const flightSearch = (params: FlightSearchParams & { onestop?: boolean }, options?: any) =>
    $apifront<Service<FlightSearchResponse>>('/fl/flight/search', { method: 'post', body: params, ...options });

  const getFlightSharedLink = (body: FlightShareParam) =>
    $apifront<Service<{ surl: string }>>('/flight/share-link', { method: 'post', body, v2: true });

  const getFlightByCode = (params: { code: string; lang: string; currency: string }) =>
    $apifront<Service<FlightByCode>>('/contents/flight/flight-code', { params });

  return {
    getAirportSuggestions,
    getAirportDefaultSuggestions,
    getFlightHomepagePromos,
    getFareCalendar,
    getPopularRoutes,
    getPopularFlights,
    getFlightSharedLink,
    flightSearch,
    flightLiveCrawl,
    flightLiveCrawlRoundTrip,
    flightLiveCrawlMonitor,
    getFlightByCode,
  };
};

export const useFlightSearchStore = defineStore('flight-search', () => {
  /** temp solution to sync with blaze & forerunner store */
  const sharedVuex = useStorage<{
    'flight-search': { searchHistory: (FlightSearchHistory & { timestamp: number })[] };
  }>(
    'shared-vuex',
    {
      'flight-search': {
        searchHistory: [],
      },
    },
    undefined,
    {
      mergeDefaults: (storageValue) => {
        const filteredFlightSearchHistory = storageValue['flight-search'].searchHistory.filter(
          (history) =>
            history.depAirport && history.depAirportProperties && !dayjs(history.depDate).isBefore(dayjs(), 'day'),
        );

        storageValue['flight-search'].searchHistory = filteredFlightSearchHistory;

        return storageValue;
      },
    },
  );

  const searchHistory = computed({
    get: () => sharedVuex.value?.['flight-search']?.searchHistory ?? [],
    set: (params) => {
      if (!sharedVuex.value?.['flight-search']) {
        sharedVuex.value['flight-search'] = { searchHistory: [] };
      }

      sharedVuex.value['flight-search'].searchHistory = params;
    },
  });

  function addSearchHistory(searchParams: FlightSearchHistory) {
    searchHistory.value = searchHistory.value.filter((flight) => !dayjs(flight.depDate).isBefore(dayjs(), 'date'));

    const propsToCheck = ['depAirport', 'arrAirport', 'depDate', 'retDate'];

    const existingHistoryIndex = findIndex(searchHistory.value, (history) =>
      isEqual(pick(history, propsToCheck), pick(searchParams, propsToCheck)),
    );

    if (existingHistoryIndex > -1) {
      searchHistory.value = searchHistory.value.filter((_, index) => index !== existingHistoryIndex);
    }

    searchHistory.value.unshift({
      ...searchParams,
      timestamp: dayjs().unix(),
    });

    if (searchHistory.value.length > 10) {
      searchHistory.value.pop();
    }
  }

  return {
    searchHistory,
    addSearchHistory,
  };
});
